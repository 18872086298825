@use "type/heading";
@use "type/copy";
@use "type/display";
@use "type/title";
@use "type/small";
@use "components/Message";

#auth {
	width: 28rem;
}

#auth-form {
	display: flex;
	flex-flow: column nowrap;
	row-gap: 1.75rem;
}

#auth-form, .auth-title {
	grid-column: inner-content;
}

.signup-footer {
	display: flex;
	flex-wrap: wrap-reverse;
	align-items: center;
	justify-content: space-between;
	column-gap: 1.75rem;
}

.auth-header {
	display: grid;
	grid-template-columns: [copy-start title-start] 1fr [title-end button-start] auto [button-end copy-end];
	grid-template-rows: [title-start] auto [title-end copy-start] auto [copy-end];
	justify-content: space-between;
	column-gap: 1rem;

	hgroup {
		display: flex;
		align-items: baseline;
		 & > p {
			 @extend %title;
			 color: var(--secondary);
		 }
	}

	.button {
		grid-row: title;
		grid-column: button;
		margin-top: -0.5ex;
	}

	.select {
		grid-column: 1 / -1;
		grid-row: copy;
		margin-top: 1rem;

		.select-trigger {
			@extend %display;
		}
	}
}

.auth-title {
	@extend %heading;
	grid-area: title / title;
	white-space: nowrap;
}



.signup-blurb {
	@extend %copy;
	margin-top: 1ex;
	grid-area: copy / copy;
}



.auth-footer {
	display: flex;
	align-items: baseline;
	justify-content: center;
	color: var(--text-secondary);
	padding-top: 2ex;
	border-top: solid 1px var(--disabled);
}
