@use "vars/dimen";
@use "vars/palette";

.pagination {
	display: grid;
	grid-template-columns: minmax(dimen.$element, auto) [pages-start] 1fr [pages-end] minmax(dimen.$element, auto);
	padding-inline: dimen.$padding-inline;
	justify-self: center;
	column-gap: dimen.$padding-inline;
	align-items: center;

	&:has(.page-item:only-child) {
		visibility: hidden;
	}
}

.pages-list {
	display: flex;
	column-gap: 1rem;
	max-width: 100%;
	overflow-x: auto;
	justify-content: center;
	padding: 0;
}

.page-item {
	display: flex;
	align-items: center;
	justify-content: center;

	.button {
		width: dimen.$element;
		padding-inline: 0;
	}
}