@use "vars/dimen";
@use "type/display";
@use "type/title";
@use "type/copy";
@use "type/small";
@use "components/Page";
@use "components/Select";

.onboarding {
	grid-template: subgrid / subgrid;


	@media #{dimen.$desktop} {
		display: grid;
	}

	@media #{dimen.$mobile} {
		&, #signup-form {
			display: flex;
			flex-flow: column nowrap;
		}
	}
}

.onboarding-header {
	grid-row: subheader;
	grid-column: content;
	padding-inline: dimen.$padding-inline;
	overflow: visible;
	display: flex;
	flex-flow: column wrap;
	row-gap: 1.75rem;
	margin-bottom: 1.75rem;

	.onboarding-title {
		@extend %display;

		& + * {
			@extend %title;
			color: var(--tertiary);
			align-self: flex-end;
		}
	}

	.onboarding-copy {
		@extend %copy;
		padding-bottom: 3.5ex;
		border-bottom: 1px solid var(--disabled);
	}
}

.onboarding-headline, .onboarding-type {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-self: start;
	gap: 2ex 1em;
}

#onboarding-success {
	grid-column: content;
	padding-inline: dimen.$padding-inline;
}

#signup-form {
	display: grid;
	grid-column: 1 / -1;
	grid-row: inner-content-start / content-end;
	grid-template-columns: subgrid;
	grid-template-rows: auto;
	align-content: start;
	justify-items: stretch;
	row-gap: 1.75rem;
	overflow: visible;


	.signup-footer {
		justify-content: flex-end;
		grid-column: inner-content-start / content-end;
		padding-inline: dimen.$padding-inline;
		row-gap: 0.875rem;
		align-items: flex-start;
		align-content: flex-start;

		@media #{dimen.$desktop} {
			grid-row: 2 / -1;
		}
	}

	fieldset {
		position: relative;
		display: flex;
		flex-flow: column nowrap;
		box-sizing: border-box;
		row-gap: 1.75rem;
		border: none;
		margin: 0;
		padding: 0;

		legend {
			@extend %title;
			padding: 0;
			margin-bottom: 2.5ex;
			color: var(--tertiary);
			align-self: stretch;
			grid-column: 1 / -1;
			justify-self: stretch;
		}

		@media #{dimen.$mobile} {
			grid-column: 1 / -1;
		}
	}

	& > fieldset {
		padding-inline: dimen.$padding-inline;
	}

	:where(#basic) {
		grid-column: sidebar-left;

		@media #{dimen.$desktop} {
			grid-row: 1 / -1;
		}
	}

	:where(#profile) {
		grid-column: inner-content-start / content-end;

		@media #{dimen.$desktop} {
			grid-row: 1 / 2;
		}
	}

	#socials {
		.social-items {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
			gap: 3rem 1.75rem;
		}
	}

	.signup-disclaimer {
		@extend %small;
		color: var(--text-tertiary);
		flex: 1 1 100%;

		a {
			color: var(--text-secondary);
		}
	}

	& > .message {
		grid-column: 1 / -1;
		margin-inline: dimen.$padding-inline;
	}
}



