@use "vars/dimen";
@use "vars/palette";
@use "partials/theme";
@use "type/title";
@use "type/copy";
@use "partials/accent";
@use "components/Message";

.gallery {
	overflow: initial;

	&:has(> .gallery-edit) {
		gap: 2rem;
	}

	.modal[open] {
		@extend %dark;
		position: fixed;
		display: grid;
		grid-template-columns: [container-start left-start] dimen.$padding-inline [left-end content-start] 1fr [content-end right-start] dimen.$padding-inline [right-end container-end];
		grid-template-rows: [header-start] auto [header-end image-start] auto [image-end copy-start] auto [copy-end];
		align-items: stretch;
		justify-items: stretch;
		background: none;
		padding: 0;
		gap: 1rem;
		margin-block: 0;
		min-height: 100%;

		img {
			grid-row: image;
			grid-column: content;
			max-width: min(100%, calc(100vw - (#{dimen.$padding-inline} * 2)));
			max-height: min(100%, calc(100vh - (#{dimen.$element} * 2)));
			object-fit: contain;
			align-self: center;
		}

		.gallery-item-content {
			grid-row: copy;
			grid-column: container;
			align-self: start;
			margin-bottom: dimen.$padding-inline;
		}

		.close-button {
			justify-self: center;
			align-self: end;
			grid-row: header;
			grid-column: left;
			position: sticky;
			top: 0;
		}
	}
}

.gallery-items {
	position: relative;
	padding: 0;
	display: grid;
	align-items: stretch;
	grid-template: repeat(2, 1fr) / repeat(2, 1fr);
	max-width: calc(100vw - #{dimen.$padding-inline-double});
	overflow: hidden;
	list-style: none;

	& > li {
		display: contents;
	}

	.gallery[role="region"] & {
		aspect-ratio: 1;
		background-image: linear-gradient(palette.$red, palette.$red),
		linear-gradient(palette.$green, palette.$green),
		linear-gradient(palette.$emeryville-blue, palette.$emeryville-blue),
		linear-gradient(palette.$clay-orange, palette.$clay-orange);
		background-size: 50% 50%, 50% 50%, 50% 50%, 50% 50%;
		background-repeat: no-repeat;
		background-position: top left, top right, bottom left, bottom right;
	}

	.gallery[role="form"] & {
		gap: dimen.$element;
	}
}

.gallery-item {
	position: relative;
	display: flex;
	background-color: var(--accent);
	box-sizing: border-box;
	max-width: 100%;
	max-height: 100%;
	padding: 0;
	border: none;
	overflow: hidden;
	justify-self: stretch;

	& > img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;

		&[aria-hidden='true'] {display: none}
	}
}

button.gallery-item {
	align-items: flex-end;
	cursor: pointer;

	& > img[aria-hidden='true'] + .gallery-item-content { transform: none; }

	@media (hover: none) {
		.gallery-item-content {
			display: none;
		}
	}

	@media (hover: hover) {
		.gallery-item-content {
			margin-bottom: 1rem;
			transform: translate3d(0, calc(100% + 1rem), 0);
			transition: transform 100ms;
		}

		&:hover {
			.gallery-item-content {
				transform: translate3d(0, 0, 0);
			}
		}
	}
}

.gallery-item-content {
	@extend %dark;
	position: relative;
	color: var(--foreground);
	background-color: var(--background);
	max-width: calc(100% - 2rem);
	padding: 1rem;
	margin-inline: auto;
	justify-self: center;

	&:only-child {
		align-self: center;
	}
}

.gallery-item-title {
	@extend %title;

	& + p {
		margin-top: 1ex;
	}
}

.gallery-item-description {
	@extend %copy;
	margin-bottom: 1ex;
}

.gallery-edit {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	column-gap: dimen.$inline;
	padding: 0.5rem 0 2rem;
	border: none;
	border-bottom: solid 1px var(--text-tertiary);

	.upload {
		flex: 2 1 10rem;
	}

	.message {
		flex: 1 1 0;
		margin-top: 1ex;
	}
}

.gallery-edit-fields {
	flex: 2 1 12rem;
	display: flex;
	flex-direction: column;
	row-gap: 2rem;

	.input:last-child {
		flex: 1 1 auto;
	}
}