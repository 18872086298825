@use "common/source/styles/vars/dimen";
@use "common/source/styles/vars/palette";
@use "common/source/styles/partials/icon";
@use "common/source/styles/partials/accessibility";

.modal {
	flex-flow: column nowrap;
	row-gap: 2rem;
	box-sizing: border-box;
	padding: 2rem dimen.$padding-inline;
	border: none;
	margin: auto;
max-width: calc(100% - #{dimen.$padding-inline});
	&[open] {
		display: flex;
		position: relative;
	}

	&::backdrop {
		background: palette.$eerie-black;
		opacity: 0.5;
	}
}

.close-button {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 4rem;
	height: 4rem;
	border: none;
	background: none;
	color: var(--foreground);
//	position: absolute;
//	top: 0;
//	left: 0;

	.button-label {
		@extend .sr-only;
	}

	&::before {
		@extend %icon;
		content: 'close';
		font-size: 2rem;
	}
}

body:has(.modal[open]) {
	overflow: hidden;
}