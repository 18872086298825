@use "vars/dimen";
@use "type/copy";
@use "type/label";
@use "partials/accent";
@use "partials/icon";
@use "components/Gallery";
@use "components/Upload";
@use "components/Message";

.detail {
	display: grid;
	grid-template: subgrid / subgrid;

	@media #{dimen.$mobile} {
		display: flex;
		flex-flow: column nowrap;
		row-gap: 1rem;
	}

	.gallery {
		padding-inline: dimen.$padding-inline;
		grid-row: inner-content;
		position: relative;

		@media #{dimen.$desktop} {
			grid-column: inner-content-start / content-end;
			grid-row: inner-content;

			&[role="region"] {
				max-width: min(#{dimen.$md-max}, calc(8rem + 50vw));
			}

			&::before {
				content: '';
				position: absolute;
				inset-block: 0;
				left: dimen.$divider * -0.5;
				display: block;
				background: var(--disabled);
				width: dimen.$divider;
			}
		}
	}

	& > .message {
		@media #{dimen.$desktop} {
			grid-column: inner-content;
			grid-row: subheader;
			align-self: center;
			justify-self: start;
			margin-inline: dimen.$padding-inline;
		}
	}
}

.detail-header {
	display: flex;
	justify-content: space-between;
	column-gap: dimen.$inline;
	padding-inline: dimen.$padding-inline;
	grid-column: content;
	max-width: unset;

	@media #{dimen.$desktop} {
		grid-row: subheader;
		padding-bottom: 2.75rem;
	}

	#share {
		grid-column: sidebar-right;
	}
}

.detail-header-actions {
	display: flex;
	flex-flow: row-reverse;

	& > .button {
		flex: 1 1 0;

		&[data-importance="tertiary"] {
			align-self: start;
			justify-content: flex-start;
			margin-left: -1rem;
		}
	}
}

.detail-content {
	@extend %accent;
	display: grid;
	grid-template: subgrid / subgrid;

	padding-inline: dimen.$padding-inline;

	&::after {
		content: '';
		align-self: stretch;
		display: block;
	}

	@media #{dimen.$mobile} {
		&::after {
			height: dimen.$divider;
			width: 100%;
			background-color: var(--disabled);
			margin-block: 1rem;
		}
	}

	@media #{dimen.$desktop} {
		grid-row: inner-content-start / content-end;
		grid-column: sidebar-left;

		&::after {
			background-image: linear-gradient(to right, var(--b1) 25%, var(--b2) 25%, var(--b2) 50%, var(--b3) 50%, var(--b3) 75%, var(--b4) 75%);
			scale: 0.5;
			transform-origin: bottom center;
			aspect-ratio: 4;
		}
	}
}

.detail-bio {
	grid-row: inner-content;
	display: flex;
	flex-flow: column nowrap;
	row-gap: 1rem;

	&:has(> .input) {
		row-gap: 2.5rem;
	}

	.input-label {
		text-transform: capitalize;
	}
}

.detail-location {
	@extend %label;
	color: var(--tertiary);
	display: flex;
	align-items: center;
	column-gap: 0.5em;

	&::before {
		@extend %icon;
		content: 'location_on';
	}

	& + .list-item-footer .list-item-description {
		-webkit-line-clamp: 2;
	}

	text-decoration: none;

	&[href] {
		color: var(--action);
	}
}

.detail-description {
	@extend %copy, %accent;

	&::after {
		content: '';
		display: inline-block;
		background-color: var(--mark);
		width: 1em;
		height: 1em;
		margin-bottom: -0.25ex;
		margin-left: 0.25em;
	}
}

.detail-links {
	display: flex;
	column-gap: 2rem;
}

.detail-link {
	$services: 'instagram', 'facebook', 'youtube', 'website';

	display: block;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 75%;
	width: 3rem;
	height: 3rem;
	opacity: 0.5;

	@each $service in $services {
		&[data-service="#{$service}"] {
			background-image: url("/images/icon-#{$service}.svg");
		}
	}

	@media (hover: hover) {
		transition: opacity 100ms;

		&:hover {
			opacity: 1;
		}
	}
}