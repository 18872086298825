@use "vars/dimen";
@use "partials/global";
@use "pages/Landing";
@use "pages/List";
@use "pages/Detail";
@use "pages/Account";
@use "components/Auth";
@use "pages/Onboarding";

:root {
	@media #{dimen.$desktop} {
		--header: max(74px, 5.25rem);
		--sidebar: minmax(auto, 28rem);
		--gutter: 1fr;
		--inner: minmax(auto, 84rem);
	}

	@media #{dimen.$mobile} {
		--header: max(49px, 3.5rem);
		--sidebar: minmax(#{dimen.$element}, auto);
		--gutter: #{dimen.$padding-inline};
		--inner: 2fr;
	}
}