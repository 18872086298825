@use "vars/dimen";
@use "partials/accessibility";
@use "components/UserProfile";
@use "components/Modal";
@use "components/Input";
@use "components/Select";
@use "components/Filters";
@use "components/Pagination";
@use "partials/accent";
@use "partials/oneline";
@use "type/heading";
@use "type/copy";
@use "type/label";
@use "type/small";

.list {
	display: grid;
	grid-template: subgrid / subgrid;
	row-gap: 3rem;
	align-items: stretch;
	position: relative;

	.list-filters {
		grid-row: content;
		grid-column: sidebar-left;
		pointer-events: none;

		& > * {
			pointer-events: auto;
		}
	}

	@media #{dimen.$mobile} {

		#list-content {
			grid-column: window;
			padding: 0;
		}

		.list-filters {
			display: grid;
			grid-template: subgrid / subgrid;
			grid-column: content;
			column-gap: dimen.$padding-inline;
			z-index: 1;
			padding: 0;
		}

		#type {
			grid-column: content-start / inner-content-end;
			grid-row: subheader;
			align-self: start;
		}

		#search-wrapper {
			grid-row: subheader;
			grid-column: sidebar-right;
		}
	}
}


#list-content {
	position: relative;
	grid-row: content;
	grid-column: inner-content-start / content-end;
	display: grid;
	grid-template: subgrid / subgrid;

	@media #{dimen.$desktop} {
		&::before {
			content: '';
			position: absolute;
			inset-block: 0;
			left: dimen.$divider * -0.5;
			display: block;
			background: var(--disabled);
			width: dimen.$divider;
			grid-row: content;
		}
	}

	.pagination {
		grid-row: subfooter;
		grid-column: 1 / -1;
	}
}

.list-header, .list-header-info {
	display: flex;
	flex-direction: row;
	align-items: baseline;
}

.list-header {
	grid-row: subheader;
	grid-column: 1 / -1;
	flex-wrap: wrap-reverse;
	justify-content: space-between;
	row-gap: dimen.$padding-inline;
	padding-inline: dimen.$padding-inline;

	.input-label {
		@extend .sr-only;
	}

	#type {
		flex: 1 4 12rem;
	}

	@media #{dimen.$mobile} {
		padding: 0;
		display: grid;
		grid-column: content;
		column-gap: dimen.$inline;
		grid-template-columns: subgrid;
		grid-template-rows: [subheader-start] auto [subheader-end footer-start] auto [footer-end];
	}
}

.list-header-info {
	flex: 4 1 16rem;
	column-gap: dimen.$inline;

	@media #{dimen.$mobile} {
		grid-row: footer;
		grid-column: content;
		align-self: start;
		justify-content: space-between;

		& > .button:last-child {
			display: none;
		}
	}
}

.list-count {
	@extend %label;
}

.list-empty, .list-items {
	grid-row: inner-content;
	grid-column: 1 / -1;
	gap: 4rem 2rem;
	align-items: stretch;
	padding-inline: dimen.$padding-inline;
}

.list-empty {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	& > p {
		@extend %label;
	}

	&::before {
		content: '';
		aspect-ratio: 1;
		width: 6rem;
		background-image: linear-gradient(var(--b1), var(--b1)),
		linear-gradient(var(--b2), var(--b2)),
		linear-gradient(var(--b3), var(--b3)),
		linear-gradient(var(--b4), var(--b4));
		background-size: 50% 50%, 50% 50%, 50% 50%, 50% 50%;
		background-repeat: no-repeat;
		background-position: top left, top right, bottom left, bottom right;
	}
}

.list-items {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(21rem, 1fr));
	list-style: none;
	align-self: start;
}

.list-item {
	display: contents;

	& > a {
		display: flex;
		flex-flow: column nowrap;
		row-gap: 1rem;
		text-decoration: none;
		max-width: 100%;
		padding-bottom: 1rem;
		border-bottom: solid 2px var(--text-primary);

		@media (hover: hover) {
			.avatar-image {
				transform-origin: center;
				transition: transform 100ms;
			}

			.avatar {
				&::before, &::after {
					transition: transform 100ms;
				}
			}

			&:hover {
				border-color: var(--action);

				.avatar-image {
					transform: scale(1.25);
				}

				.avatar {
					&::before, &::after {
						transform: rotate3d(0, 0, 1, 45deg);
					}
				}
			}
		}
	}
}

.list-item-images {
	@extend %accent;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	list-style: none;
	padding: 0;
	width: 100%;
	aspect-ratio: 4;
	background-image: linear-gradient(to right, var(--b1) 25%, var(--b2) 25%, var(--b2) 50%, var(--b3) 50%, var(--b3) 75%, var(--b4) 75%);
}

.list-item-image {
	position: relative;
	aspect-ratio: 1;

	img {
		position: absolute;
		inset: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;

		&[aria-hidden='true'] {
			display: none
		}
	}
}

.list-item-footer {
	flex: 1;
	display: flex;
	flex-direction: row-reverse;
	align-items: flex-end;
	justify-content: space-between;
	column-gap: 0.125em;
}

.list-item-description {
	@extend %oneline, %copy;
	color: var(--text-primary);
	-webkit-line-clamp: 3;
	align-self: flex-start;
}

.list-item-overflow {
	@extend %label;
	color: var(--action);

	@media (hover: hover) {
		color: var(--text-tertiary);

		.list-item-link:hover & {
			color: var(--action);
		}
	}
}
