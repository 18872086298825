@use "vars/dimen";
@use "partials/accessibility";
@use "partials/theme";
@use "type/title";
@use "components/Message";

.upload {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows:
			[title-start]
			minmax(0, auto)
			[title-end content-start]
			1fr
			[action-start]
			auto
			[action-end content-end];
	justify-items: stretch;
	align-items: stretch;

	&:has(> label:first-child) {
		aspect-ratio: 1;
	}

	& > * {
		grid-column: 1 / -1;
	}

	input {
		@extend .sr-only;
	}

	label {
		@extend %dark;
		grid-row: content;
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		max-width: 100%;
		overflow: hidden;
		cursor: pointer;
		aspect-ratio: 1;
	}

	img {
		@extend %dark;
		z-index: 1;
		grid-row: content;
		display: block;
		width: 100%;
		max-width: 100%;
		object-fit: cover;
		pointer-events: none;
		aspect-ratio: 1;
		align-self: stretch;
		justify-self: stretch;

		& + label {
			display: contents;

			.button {
				z-index: 1;
				grid-row: action;
				grid-column: 1 / -1;
				margin: dimen.$inline;
				align-self: end;
				justify-self: end;
				padding-inline: dimen.$inline;
			}
		}
	}

	.button.icon {
		background: var(--secondary);
		border-color: var(--secondary);
		margin: 0;
		--button-color: var(--background);
	}

	& > .message {
		margin-top: 1ex;
	}
}

.upload-heading {
	@extend %title;
	color: var(--primary);
	margin-bottom: 1ex;
}

.image-upload-preview {
	position: absolute;
	inset: 0;
	object-fit: cover;
	object-position: center;
}